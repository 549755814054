import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Modal, ModalBody, ModalHeader, ModalFooter, Form, Input } from 'reactstrap';
import InlineFilters from '../filters/InlineFilters';
import { convertFilterOptionsToAlgoliaAttributes, parseFilterNames } from '../../helpers/inbdeUtils';
import { isIterableArray } from '../../helpers/utils';
import { toast } from 'react-toastify';

// const getTimeStampFromYear = year => {
//   const date = new Date(year, 0, 1);
//   return date.getTime();
// };

// const getYearFilterOptions = startYear => {
//   const currentYear = new Date().getFullYear();
//   const listOfYears = [];
//   let counter = 1;

//   for (let year = startYear; year <= currentYear; year += 1) {
//     listOfYears.push({ id: counter, name: year + '', selected: false, value: getTimeStampFromYear(year) });
//     counter += 1;
//   }

//   return listOfYears;
// };

// const startYear = 2019;
// const additionalFilters = [
//   {
//     key: 'created_in_year',
//     filterValue: 'created_on',
//     label: 'Year of Creation',
//     selectedFilterLabel: 'Year',
//     isAdditional: true,
//     options: getYearFilterOptions(startYear)
//   }
// ];
const filterQuery = 'is_deleted=0 AND is_flagged=0';
let newFilterQuery = filterQuery;

const GenerateReport = ({ runReportQuery }) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterNames, setFilterNames] = useState('');
  const [name, setName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = e => {
    setName(e.target.value);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setName('');
    }
    setIsModalOpen(!isModalOpen);
  };

  const clearSearch = () => {
    newFilterQuery = filterQuery;
    setFilterOptions([]);
  };

  const filterTestlets = filterOptions => {
    setFilterOptions(filterOptions);
    setFilterNames(parseFilterNames(filterOptions));
  };

  const runQuery = e => {
    e && e.preventDefault();

    if (name.trim()) {
      const searchParamsAndOptions = {};
      const { filterAttributes, updatedNewFilterQuery } = convertFilterOptionsToAlgoliaAttributes(
        filterOptions,
        newFilterQuery,
        filterQuery
      );

      newFilterQuery = updatedNewFilterQuery;

      searchParamsAndOptions['facetFilters'] = filterAttributes;
      searchParamsAndOptions['filters'] = newFilterQuery;
      searchParamsAndOptions['hitsPerPage'] = 5000;

      runReportQuery(searchParamsAndOptions, name);
      toggleModal();
    } else {
      toast.error('Please enter a name for the report');
    }
  };

  return (
    <div>
      <Row className="ml-2 mt-3 mb-2">
        <InlineFilters
          filterTestlets={filterTestlets}
          clearFilters={clearSearch}
          isAdminsView={true}
          // additionalFilters={additionalFilters}
        />
      </Row>
      <Row>
        <Button color="primary" onClick={toggleModal} className="ml-auto">
          Run Query
        </Button>
      </Row>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Generate Report</ModalHeader>
        <ModalBody>
          <div className="mb-4">
            {isIterableArray(filterOptions) ? (
              <h6 className="fs-0">
                You have selected the following filters to generate the report:
                <br />
                <br />
                {filterNames}
              </h6>
            ) : (
              <h6 className="fs-0">
                You have not selected any filters. A report will be generated for all the content in the system
              </h6>
            )}
          </div>
          <p className="mb-4">
            Please enter a name for the generated report. Once created, you will be able to see the report in the table
            of generated reports under the <strong>System Generation Reports</strong> tab.
          </p>
          <Form onSubmit={runQuery}>
            <Input
              onChange={handleChange}
              placeholder="Please enter the report name"
              type="text"
              value={name}
              required
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="mr-2" onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={runQuery}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

GenerateReport.propTypes = {
  runReportQuery: PropTypes.func
};

export default GenerateReport;
