import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Nav, Navbar } from 'reactstrap';
import { navbarBreakPoint } from '../../config';
import Logo from './Logo';
import routes from '../../routes';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import AppContext from '../../context/AppContext';

const isBurgerMenuShown = () => {
  const burgerMenu = document.getElementById('burgerMenu');
  return !(getComputedStyle(burgerMenu).display === 'none');
};

const NavbarVertical = ({ userAccess }) => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);

  const handleNavbarVerticalCollapse = () => isBurgerMenuShown() && setShowBurgerMenu(!showBurgerMenu);

  let approvedRoutes;
  if (userAccess === 'admin') {
    approvedRoutes = routes;
  } else {
    approvedRoutes = routes.filter(route => {
      return route.name.toLowerCase() !== 'admin';
    });
  }

  return (
    <Fragment>
      <Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light>
        <Logo at="navbar-vertical" width={40} className="mb-5" />
        <Collapse navbar isOpen={showBurgerMenu}>
          <Nav navbar vertical>
            <NavbarVerticalMenu routes={approvedRoutes} handleNavbarVerticalCollapse={handleNavbarVerticalCollapse} />
          </Nav>
          <div className={`px-3 px-${navbarBreakPoint}-0`}>
            <hr className="border-300 mb-3 mt-2" />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                fontSize: 'small'
              }}
              className="text-black text-sm mb-4"
            >
              <div>{`© ${getYear()} New York University.`}</div>
              <div>NYU College of Dentistry.</div>
              <div>All rights reserved.</div>
            </div>
          </div>
        </Collapse>
      </Navbar>
    </Fragment>
  );
};

const getYear = () => {
  return new Date().getFullYear();
};

NavbarVertical.propTypes = {
  userAccess: PropTypes.string
};

export default NavbarVertical;
