import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import '../Firebase/FirebaseService';
import { getCurrentTimeStamp, isProd } from '../../helpers/inbdeUtils';
import { projectName } from '../../helpers/constants';

// set firebase functions regions (us-east1 for prod and us-central1 for qa)
const REACT_APP_FIREBASE_REGION = isProd() ? 'REACT_APP_FIREBASE_REGION_PROD' : 'REACT_APP_FIREBASE_REGION_QA';
const FIREBASE_FUNCTIONS_REGION = process.env[REACT_APP_FIREBASE_REGION] || 'us-central1';

const functions = firebase.app().functions(FIREBASE_FUNCTIONS_REGION);
const reports = firebase.firestore().collection('reports');
const storage = firebase.storage();
const storageRef = storage.ref();

class ReportService {
  async createNewReport(reportFilters, query, reportName) {
    const generateReport = functions.httpsCallable('generateINBDEReport');

    try {
      const res = await generateReport({ reportFilters, query, reportName });
      if (res) {
        return res.data;
      }
    } catch {}

    return null;
  }

  async getPastReports() {
    try {
      const collection = await reports
        .where('project', '==', projectName)
        .orderBy('created_at', 'desc')
        .limitToLast(5000)
        .get();
      return collection;
    } catch (e) {
      return null;
    }
  }

  async getReportDownloadUrl(path) {
    try {
      const reportRef = storageRef.child(path);
      const downloadUrl = await reportRef.getDownloadURL();

      return downloadUrl;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async saveReportToFirestore(pathToReport, reportObjectMetadata, reportUid) {
    try {
      const reportFilters = reportObjectMetadata['filters'] ? reportObjectMetadata['filters']['facetFilters'] : [];
      const downloadUrl = await this.getReportDownloadUrl(pathToReport);

      const reportDoc = {
        name: reportObjectMetadata['name'],
        created_at: getCurrentTimeStamp(),
        created_by: reportObjectMetadata['createdBy'],
        filters: { ...reportFilters },
        path: pathToReport,
        url: downloadUrl,
        project: projectName
      };

      await reports.doc(reportUid).set(reportDoc);
      reportDoc['id'] = reportUid;

      return reportDoc;
    } catch (e) {
      console.error('Error saving report doc to reports collection in Firestore:', e);
      return null;
    }
  }
}

export default ReportService;
