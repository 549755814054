import React, { Component, Fragment } from 'react';
import StatusTabs from '../components/review/StatusTabs';
import GenerateReport from '../components/reports/GenerateReport';
import ReportDownloadModal from '../components/reports/ReportDownloadModal';
import PastReportsTable from '../components/reports/PastReportsTable';
import ReportService from '../services/Reports/ReportService';
import SpinnerModal from '../components/common/SpinnerModal';
import { UserContext } from '../Contexts';
import { isIterableArray } from '../helpers/utils';
import { isFeatureActive } from '../helpers/inbdeUtils';
import featureToggles from '../services/Feature/toggles';
import { toast } from 'react-toastify';

const statuses = [
  { id: 1, value: 'Create New Report', linkTo: '#' },
  { id: 2, value: 'Generated Reports', linkTo: '#' }
];

class Reports extends Component {
  _isMounted = false;
  reportService = new ReportService();

  constructor(props) {
    super(props);
    this.state = {
      statuses: statuses,
      selectedTab: statuses[0],
      isGenerateReportSection: true,
      isLoading: false,
      pastReports: [],
      report: null,
      reportModalFlag: false,
      user: null,
      isReportError: false,
      toggles: {}
    };

    this.changeTab = this.changeTab.bind(this);
    this.emailReport = this.emailReport.bind(this);
    this.runReportQuery = this.runReportQuery.bind(this);
    this.toggleReportModal = this.toggleReportModal.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.context) {
      const { user, toggles } = this.context;
      this.setState({ user, toggles });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeTab(newTab) {
    const { pastReports } = this.state;
    const isGenerateReportSection = newTab['id'] === statuses[0]['id'];
    if (!isGenerateReportSection && pastReports.length < 1) {
      this.fetchReports();
    }

    this._isMounted && this.setState({ selectedTab: newTab, isGenerateReportSection });
  }

  emailReport() {
    // get report information i.e. name and download link
    // get user information i.e. id and email
    // send email to user with information in email body
  }

  async runReportQuery(params, reportName) {
    const { user, toggles } = this.state;

    const errorMessage = 'generate report';
    if (!isFeatureActive(featureToggles.isReportsGenerationFeatureEnabled, toggles, errorMessage)) {
      return;
    }

    this._isMounted && this.setState({ isLoading: true });

    const query = '';
    const reportObjectMetadata = { name: reportName, createdBy: user, filters: params };

    const report = await this.reportService.createNewReport(params, query, reportName);

    if (report && report.data) {
      const { data } = report;
      const { path, id } = data;
      const reportDoc = await this.reportService.saveReportToFirestore(path, reportObjectMetadata, id);

      this._isMounted &&
        this.setState({ reportModalFlag: true, report: reportDoc, isReportError: false, pastReports: [] });
    } else {
      // no data fetched, but query was a success i.e. no testlets found against filters
      if (report && report.success && report.errorCode === 'ERR-002') {
        this._isMounted && this.setState({ reportModalFlag: true, report: null, isReportError: false });
      } else {
        // encountered some error in running the report
        this._isMounted && this.setState({ reportModalFlag: true, report: null, isReportError: true });
      }
    }

    this._isMounted && this.setState({ isLoading: false });
  }

  async fetchReports() {
    const reportsCollection = await this.reportService.getPastReports();

    if (reportsCollection) {
      try {
        const reportDocs = [];
        reportsCollection.forEach(doc => {
          const { created_at, created_by, name, url, filters, path } = doc.data();

          const reportDoc = { created_at, created_by, name, url, path };

          const filterNames = [];
          const filterNameMap = {};
          for (let i in filters) {
            filters[i].forEach(filter => {
              const name = filter.substring(0, filter.indexOf(':'));
              filterNameMap[name] = true;
            });
          }

          for (let key in filterNameMap) {
            filterNames.push(key);
          }

          reportDoc['filters'] = isIterableArray(filterNames) ? filterNames.join('; ') : 'All Content';
          reportDoc['id'] = doc.id;

          reportDocs.push(reportDoc);
        });

        this._isMounted && this.setState({ pastReports: reportDocs });
      } catch {
        toast.error('There was an error in fetching generated reports');
      }
    }
  }

  toggleReportModal() {
    this._isMounted && this.setState(state => ({ reportModalFlag: !state.reportModalFlag }));
  }

  render() {
    const {
      isGenerateReportSection,
      isLoading,
      pastReports,
      selectedTab,
      statuses,
      report,
      reportModalFlag,
      isReportError
    } = this.state;

    return (
      <Fragment>
        {isLoading && <SpinnerModal background={'bg-black opacity-50'} />}
        <div className="min-vh-75 min-vh-lg-100">
          <div className="mb-lg-4 mb-2 border-bottom border-400">
            <div className="pl-2">
              <div className="pr-2 pt-2 pb-2">
                <div className="title reports-page-title pr-2 pt-2 pb-2">
                  <h3>Reports</h3>
                </div>
              </div>
              <div>
                <StatusTabs stasuses={statuses} selectedTab={selectedTab} changeTab={this.changeTab} statusCount={[]} />
              </div>
            </div>
          </div>
          <div className="reports-page-content">
            {isGenerateReportSection && <GenerateReport runReportQuery={this.runReportQuery} />}
            {!isGenerateReportSection && <PastReportsTable reports={pastReports} />}
            <ReportDownloadModal
              modalFlag={reportModalFlag}
              report={report}
              toggleModal={this.toggleReportModal}
              emailReport={this.emailReport}
              isError={isReportError}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

Reports.contextType = UserContext;

export default Reports;
